<template>
    <div>
        <row ref="row" :base="$options.name"></row>
    </div>
</template>

<script>
    import row from '@/components/row.vue'

    export default {
        name: "home",
        components:{
            row
        },
        data() {
            return {
                list:[
                    {
                        title: "PC助手简介",
                        text:[
                            '#PC助手是帮助玩家自动完成一些日常活动的辅助工具,以图形识别屏幕,二叉树逻辑判断,最后模拟按键的方式完成任务,开发语言是C#',
                            '#支持所有windows系统,win7支持后台多开、win10、win11支持前台使用(也可以多开,但是是前台,也只能控制一个游戏)',
                            '#win10、win11有多开需求可以使用虚拟机,在基础教程里有虚拟机的安装和使用教程',
                        ],
                        img:[]
                    },
                    {
                        title: "程序下载",
                        text:[
                            '#在电脑浏览器(禁止使用Edge浏览器)的地址栏输入"www.ookan.com"后, 点击"PC助手下载"下载助手压缩包(图1)',
                            '#或者在教程里点击右上角的"助手下载"下载助手压缩包(图2)',
                        ],
                        img:[
                            "1.png",
                            "2.png",
                        ]
                    },
                    {
                        title: "下载可能遇到的问题",
                        text:[
                            '1.虽然不建议用"Edge浏览器"下载助手,但是还是有好多人下载,然后就会出现图1的问题',
                            '解决办法:换个浏览器下载,',
                        ],
                        img:[
                            "3.png",
                        ]
                    },
                    {
                        title: "解压压缩包",
                        text:[
                            "#下载完成后,找到下载的压缩包并解压到桌面,请勿直接在压缩包运行",
                            "*电脑没有解压软件的需要自行下载,所有的解压软件都可以解压PC助手的压缩包"
                        ],
                        img:[
                            "4.png",
                        ]
                    },
                    {
                        title: "程序目录说明",
                        text:[
                            "#解压完成后,打开文件夹,目录如图所示",
                            '*注意:图中所示的文件必须完整才可以运行,如果缺失,必然是被"windows安全中心"或者"杀毒"删除了,' +
                            '查看左侧"被识别病毒设置"教程,然后从第4步重新解压'
                        ],
                        img:[
                            "5.png",
                        ]
                    },
                    {
                        title: "程序运行",
                        text:[
                            '#以管理员身份运行PC助手,需要5秒左右的等待时间(图1)',
                            '#运行成功: 弹出助手界面后,使用微信扫一扫登录(图1)',
                            '*注意: win10,win11弹出图2对话框,请看图中操作!',
                            '#运行失败: 弹出错误消息提示,请看下一步教程',
                        ],
                        img:[
                            "6.png",
                            "17.png",
                        ]
                    },
                    {
                        title: "错误消息框解决汇总",
                        text:[
                            '#图1: 是没有以管理员身份运行PC助手文件',
                            '解决办法: 右键点击PC助手文件以管理员身份运行',
                            '#图2: 是"windows安全中心"或者"杀毒"删除了助手目录下的文件',
                            '解决办法: 查看左侧"被识别病毒设置"教程,然后从第三步重新解压',
                            '#图3,图4,图5,图6: 是系统缺少.NET Framework支持库(此库是window运行软件必备的,此提示大多出现在win7)',
                            '解决办法: 打开链接"https://www.123pan.com/s/nmyojv-kbl3A.html"下载.NET Framework 4.8支持库,' +
                            '下载完成后安装,安装后重启电脑,回到上一步继续',
                        ],
                        img:[
                            "8.png",
                            "9.png",
                            "10.png",
                            "11.jpg",
                            "12.png",
                            "13.png",
                        ]
                    },
                    {
                        title: "登录程序后充值",
                        text:[
                            '#登录之后(图1), 新用户会员时间是过期的,需要点击充值,然后选择对应套餐(图2)进行扫码充值(请勿扫图中二维码充值)' +
                            '新用户建议购买1天体验',
                        ],
                        img:[
                            "14.png",
                            "15.png",
                        ]
                    },
                    {
                        title: "开始使用",
                        text:[
                            '#在小程序充值成功后,点击关闭充值页面,助手会自动重新登录,此时会员时间是充值好的,到账有时会延迟几秒,' +
                            '注意:如果没有到账,还是提示请充值,在助手设置页-点击退出登录-重新扫码登录,如果还是不能解决,请联系客服微信' +
                            '或在小程序联系客服',
                            '#图1是PC助手功能区域的说明',
                            '*以上步骤已完成,请点击左侧菜单,查看"初始环境教程",使用PC助手必须要初始环境成功!!!'
                        ],
                        img:[
                            "16.png",
                        ]
                    },
                ],
            }
        },
        methods:{

        },
        mounted() {
            this.$refs.row.initData(this.list);
        }
    }
</script>

<style scoped>

</style>
